export default {
    namespaced: true,
    state: () => ({
        meta_data: {
            title: "Solicitud en transito",
        },
        main_dir: "/images",
        icons_dir: "/icons",
        title: "",
        step: "",
        subtitle: "",
        content: "",
        icon: "",
        updated_at: "",
        data_loaded: false,
        api_data: {
            status_content: {
                data: [],
                endpoint: "associates/associate/logged_status/",
                data_count: 0,
                mutation: "ProgressData/mutationAPI_Content",
            },
        },
    }),
    mutations: {
        mutationAPI_Content(state, data) {
            // console.log(state)
            // console.log(data)
            if (data.is_registered) {
                // Logic to replace messages content with the ones from OutSystems
                let messages = state.messages.messages_list.data;
                let comment = state.exAssociate.data.comment_approved;

                for (let i = 0; i < messages.length; i++) {
                    if (data.title == messages[i].name) {
                        data.content = messages[i].message;
                    }
                    if (
                        data.title == "Documentación Pendiente" &&
                        messages[i].name == "Documentación rechazada"
                    ) {
                        data.content = comment;
                        data.subtitle = messages[i].message;
                    }
                }

                state.title = data.title;
                state.subtitle = data.subtitle;
                state.content = data.content;
                state.step = data.step;
                state.icon = "/images/icons/blue/" + data.image;
                state.updated_at = "Última actualización: " + data.updated_at;
                state.data_loaded = true;
                this.commit("StatusBarData/mutation_SetStepEnabled", state.step);
            } else {
                this.commit("rt_redirect", "login", { root: true });
            }
        },
    },
    actions: {
        async getStatusContent({ state, dispatch, rootState }) {
            await dispatch("MessagesData/fetchMessagesList", null, { root: true });
            state.messages = rootState.MessagesData;
            state.exAssociate = rootState.PendingDocumentsData.api_data.associate;
            dispatch(
                "AxiosVuex/__request_gt",
                {
                    endpoint: state.api_data.status_content,
                    mutation: [],
                },
                { root: true }
            ).then(() => {
                this._state.data.app_loaded = true;
            });
        },
    },
    getters: {},
};
