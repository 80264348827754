export default {
    namespaced: true,
    state: () => ({
        show_modal: false,
        image: {
            url: "",
            alt: "",
        },
        original_content: "",
        content: "",
        class: "alert-default",
        timer_in_progress: false,
        api_data: {
            handle_request: {
                data: { 'name': "No Ex-Asociado" },
                endpoint: 'catalogs/messages/single/',
                data_count: 0,
                mutation: 'ModalData/mutation_AddContent',
            },
        }
    }),
    mutations: {
        mutation_AddContent(state, data) {
            state.content = data.message
            state.original_content = data.message
            state.data_loaded = true
        },
        mutation_show(state, data = '') {
            if (data != "") {
                state.content = data
            }
            state.show_modal = true
        },
        mutation_hiddenOnClick(state) {
            state.show_modal = false
            state.content = state.original_content
        },
        mutation_hiddenAfterTime(state) {
            setTimeout(function() {
                state.show_modal = false
                state.content = state.original_content
            }, 6000)
        },
    },
    actions: {
        show({ commit }, message) {
            commit('mutation_show', message)
            // commit('mutation_hiddenAfterTime')
        },
        show_max_try_error({ commit }) {
            commit('mutation_show', "Intentos de inicio de sesión excedidos. Intentalo nuevamente en " + process.env.VUE_APP_MAX_TRY_LOGIN_BAN_MINUTES + " " + (parseInt(process.env.VUE_APP_MAX_TRY_LOGIN_BAN_MINUTES) != 1 ? "minutos" : "minuto"))
            // if (!state.timer_in_progress) {
            //     state.timer_in_progress = true
            // }
        },
        show_documentation_error({ commit }) {
            commit('mutation_show', "Se ha generado un error al momento de subir el documento. Por favor inténtalo de nuevo")
            // if (!state.timer_in_progress) {
            //     state.timer_in_progress = true
            // }
        },
        load_message({ state, dispatch }) {
            if (process.env.VUE_APP_MAINTENANCE_MODE != "1") {
                dispatch('AxiosVuex/__request_post', {
                    endpoint: state.api_data.handle_request,
                    mutation: []
                }, { root: true })
            }
        }
    }
}