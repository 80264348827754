<template>
  <div id="ptu_exassociates-navbar">
    <img alt="bosch navbar" class="d-flex bosch-navbar" src="/images/bosch_bar.svg" />
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" @click.prevent="ac('home')">
        <div style="display: flex; justify-content: center; align-items: center">
          <img alt="bosch logo" class="bosch-logo c-pointer" src="/images/logo.svg" />
          <p class="text-bold m-0 font-28 d-md-inline" style="padding-left: 30px">
            {{ $store.state.application }}
          </p>
        </div>
      </a>

      <div v-if="isEditable" class="ml-auto text-bold mr-2 font-24 d-none d-md-block">
        <div>
          <div
            style="display: flex; flex-direction: column; align-items: flex-end; font-size: 12px"
          >
            <p class="c-pointer" style="margin: 0px" @click="toggleMenu">
              {{ userName }}
            </p>
            <div>
              <p class="c-pointer" style="margin: 0px; color: #007bc0" @click="toggleMenu">
                <span><img style="width: 20px" src="../../public/images/user.svg" /></span
                >{{ email }}
              </p>
            </div>
          </div>

          <div v-if="showMenu" class="logout-option">
            <p class="float-right font-12 d-block" @click="toggleMenu">
              {{ phone }}
            </p>

            <a @click.prevent="ac('exassociatedata')" class="float-right font-12 d-block c-pointer">
              Editar datos de contacto
            </a>

            <a
              style="box-shadow: 0 -1px 0 #000; margin-top: 10px"
              @click.prevent="ac('logout')"
              class="float-right font-12 d-block c-pointer"
            >
              Cerrar sesión
            </a>
          </div>
        </div>
      </div>
      <div v-else class="ml-auto text-bold mr-2 font-24 d-block">
        <a @click.prevent="ac('logout')" class="float-right font-12 d-block c-pointer">
          <img alt="logout" class="exit_icon" src="/images/exit.svg" />
          <span> Cerrar sesión </span>
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  props: {
    storeRoute: {
      default: "NavbarBoschData",
      Type: String,
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
      // console.log("User Name:", this.userName);
      // console.log("Email:", this.email);
    },
  },
  computed: {
    ...mapState({
      phone: (state) => "+52 " + state.PendingDocumentsData?.api_data.associate.data.phone,
      userName: (state) => state.PendingDocumentsData?.api_data.associate.data.ex_associate?.name,
      email: (state) => state.PendingDocumentsData?.api_data.associate.data?.email,
      isEditable: (state) => (state.ProgressData.step == "" ? false : true),
    }),
  },

  mounted() {
    // console.log("Initial User Name:", this.userName);
    // console.log("Initial Email:", this.email);

    let vm = this;
    console.log("Vuex State:", this.$store.state.PendingDocumentsData.api_data.associate);

    let loop_interval = setInterval(() => {
      if (vm.loaded) {
        // vm.ac('getDomains')
        // vm.ac('getDomainsBeneficiary')
        // vm.ac('getAssociate')
        // vm.ac('getLadas')
        // vm.ac('getBeneficiaries')

        clearInterval(loop_interval);
      }
    }, 1000);
  },
};
</script>

<style lang="scss">
@import "../assets/styles/components/_navbarBoschComponent.scss";

.logout-option {
  position: absolute;
  padding: 15px;
  background-color: #ffffff;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
</style>
