import { useCookies } from "vue3-cookies";

export default {
    namespaced: true,
    state: () => ({
        associate: {
            data: [],
            endpoint: 'associates/associate/logged/',
            data_count: 0,
            mutation: 'NavbarBoschData/mutationAPI_AssociateLogged',
        },
    }),
    mutations: {
        removeLocalSession() {
            const { cookies } = useCookies();
            cookies.remove('_refresh');
            cookies.remove('_token');
            // cookies.remove('_period_enabled');
        },
    },
    actions: {
        async logout({ commit }) {
            commit("removeLocalSession");
            location.href = "/";
        },
        async home() {
            location.href = "/";
        },
        async exassociatedata({ commit }) { 
            commit('rt_redirect', 'edit-information', { root: true });
        },
      
        
    },
    getters: {},
};
