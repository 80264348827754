export default {
    namespaced: true,
    state: () => ({
        meta_data: {
            title: "registro de datos",
        },
        title: "Documentación pendiente",
        text_one:
            "Para completar tu proceso de PTU. Es necesario que nos reenvies la siguiente documentación:",
        show_form: false,
        form_sended: false,
        form: {
            familiar_data: [
                {
                    label: "INE del familiar (.pdf max 2mb)",
                    name: "familiar_ine",
                    type: "file",
                    required: true,
                    value: "",
                    mutation: "FamiliarIne",
                },
            ],
            foreign_form: [
                {
                    label: "Comprobante de domicilio (.pdf max 2mb)",
                    name: "file_aditional_1",
                    type: "file",
                    required: true,
                    value: "",
                    mutation: "AddressFile",
                },

                {
                    label: "CLABE interbancaria activa",
                    name: "clabe",
                    type: "tel",
                    required: true,
                    no_spacing: true,
                    required_length: "18",
                    max_length: "18",
                    value: "",
                    mutation: "Clabe",
                },
            ],
            general_content_cfdi: [
                {
                    label: "Constancia de situación fiscal (.pdf max 2mb)",
                    name: "cfdi",
                    type: "file",
                    required: true,
                    value: "",
                    mutation: "CFDI",
                },
            ],
        },
        api_data: {
            associate: {
                data: [],
                endpoint: "associates/associate/logged/",
                data_count: 0,
                mutation: "PendingDocumentsData/mutationAPI_AssociateLogged",
            },
            handle_files: {
                data: {},
                endpoint: "associates/associate/save_documents/",
                mutation: "",
            },
            handle_clabe: {
                data: {
                    ex_associate: {},
                },
                endpoint: "associates/associate/(id)/is_modified/",
                mutation: "",
            },
        },
    }),

    mutations: {
        mutation_FamiliarIne(state, newValue) {
            let key = "familiar_ine";
            state.form.familiar_data.find(function (element) {
                if (element["name"] == key) {
                    element.value = newValue;
                }
            });
        },

        mutation_AddressFile(state, newValue) {
            let key = "file_aditional_1";
            state.form.foreign_form.find(function (element) {
                if (element["name"] == key) {
                    element.value = newValue;
                }
            });
        },
        mutation_CFDI(state, newValue) {
            let key = "cfdi";
            state.form.general_content_cfdi.find(function (element) {
                if (element["name"] == key) {
                    element.value = newValue;
                }
            });
        },
        mutation_Clabe(state, newValue) {
            let key = "clabe";
            state.form.foreign_form.find(function (element) {
                if (element["name"] == key) {
                    element.value = newValue;
                }
            });
        },
        mutationAPI_AssociateLogged(state, data) {
            state.api_data.associate.data = data;
            state.api_data.associate.data_count = data.length;

            //Set CLABE value for foreign person
            state.form.foreign_form[1].value = data.clabe || "";
            console.log(data);
        },
        meta(state, metadata) {
            state.meta_data[metadata];
        },
    },

    actions: {
        async getAssociate({ state, dispatch }) {
            dispatch(
                "AxiosVuex/__request_gt",
                {
                    endpoint: state.api_data.associate,
                    mutation: [],
                },
                { root: true }
            ).then(() => {
                state.show_form = true;
                this._state.data.app_loaded = true;
            });
        },

        async send_data({ state, dispatch, commit }) {
            // Validate that user can send documents
            const responseDocuments = await dispatch("send_documents");

            if (responseDocuments.status !== 200) {
                state.form_sended = false;
                state.show_form = true;
                return false;
            }

            // If person is foreign, send clabe to edit exassociate information (send to the backend)
            if (state.api_data.associate.data.is_foreign) {
                // Add and STORE CLABE
                state.api_data.handle_clabe.data.ex_associate = {
                    foreign_clabe: state.form.foreign_form[1].value,
                };

                try {
                    commit(
                        "replace_parameters_url",
                        {
                            namespace: "PendingDocumentsData",
                            api: "handle_clabe",
                            parameters: {
                                id: state.api_data.associate.data.id,
                            },
                        },
                        { root: true }
                    );
                } catch (error) {
                    console.log(error);
                }

                commit("app_loaded_set", false, { root: true });
                state.form_sended = true;
                state.show_form = false;

                // Use API to modificate exassociate data
                const response = await dispatch(
                    "AxiosVuex/__request_update",
                    {
                        endpoint: state.api_data.handle_clabe,
                        mutation: [],
                    },
                    { root: true }
                );

                console.log("AFTER REQUEST:", response);
                commit("rt_redirect", "progress", { root: true });
            } else {
                commit("rt_redirect", "progress", { root: true });
            }
        },

        async send_documents({ state, commit, dispatch }) {
            try {
                state.api_data.handle_files.data = new FormData();
                let current_state = state.api_data.associate.data;
                commit(
                    "validateFields",
                    { namespace: "PendingDocumentsData", state: "general_content_cfdi" },
                    { root: true }
                );
                if (state.form_error) {
                    return false;
                }

                if (current_state.is_foreign == 1) {
                    commit(
                        "validateFields",
                        { namespace: "PendingDocumentsData", state: "foreign_form" },
                        { root: true }
                    );
                    if (state.form_error) {
                        return false;
                    }
                }

                if (current_state.is_representative == 1) {
                    commit(
                        "validateFields",
                        { namespace: "PendingDocumentsData", state: "familiar_data" },
                        { root: true }
                    );
                    if (state.form_error) {
                        return false;
                    }
                }

                state.api_data.handle_files.data.append(
                    "cfdi",
                    state.form.general_content_cfdi[0].value
                );

                if (current_state.is_foreign == 1) {
                    state.api_data.handle_files.data.append(
                        "address",
                        state.form.foreign_form[0].value
                    );
                } else {
                    state.api_data.handle_files.data.delete("address");
                }

                if (current_state.is_representative == 1) {
                    state.api_data.handle_files.data.append(
                        "representative_ine",
                        state.form.familiar_data[0].value
                    );
                } else {
                    state.api_data.handle_files.data.delete("representative_ine");
                }

                const response = await dispatch(
                    "AxiosVuex/__request_update_files",
                    {
                        endpoint: state.api_data.handle_files,
                        mutation: [],
                    },
                    { root: true }
                );

                return response;
            } catch (error) {
                state.form_sended = false;
                state.show_form = true;
            }
        },
    },
};
