export default {
    namespaced: true,
    state: () => ({
        meta_data: {
            title: "registro de datos",
        },
        title: "Editar los datos de tú usuario",
        text_one:
            "En caso de que necesites modificar tus datos, por favor, haz los cambios necesarios en el siguiente formulario:",
        show_form: false,
        form_sended: false,
        // form: {
        //     'familiar_data': [{
        //             'label': 'INE del familiar (.pdf max 2mb)',
        //             'name': 'familiar_ine',
        //             'type': 'file',
        //             'required': true,
        //             'value': '',
        //             'mutation': "FamiliarIne",
        //         },

        //     ],
        //     'foreign_form': [{
        //         'label': 'Comprobante de domicilio (.pdf max 2mb)',
        //         'name': 'file_aditional_1',
        //         'type': 'file',
        //         'required': true,
        //         'value': '',
        //         'mutation': "AddressFile",
        //     }, ],
        //     'general_content_cfdi': [{
        //         'label': 'Constancia de situación fiscal (.pdf max 2mb)',
        //         'name': 'cfdi',
        //         'type': 'file',
        //         'required': true,
        //         'value': '',
        //         'mutation': "CFDI",
        //     }],
        // },

        form: {
            general_content: [
                {
                    label: "CURP",
                    readonly: true,
                    name: "curp",
                    type: "text",
                    required: true,
                    max_length: "18",
                    required_length: "18",
                    no_spacing: true,
                    value: "",
                    mutation: "Curp",
                },
                {
                    label: "Nombre(s) y apellido(s)",
                    readonly: true,
                    name: "first_name",
                    type: "text",
                    required: true,
                    value: "",
                    mutation: "FirstName",
                },
                {
                    label: "Teléfono celular",
                    name: "phone",
                    no_spacing: true,
                    type: "tel",
                    required: true,
                    value: "",
                    required_length: "10",
                    max_length: "10",
                    mutation: "Phone",
                    class_styles: "col-8",
                },
                {
                    label: "Correo electrónico",
                    name: "email",
                    type: "text",
                    no_spacing: true,
                    required: true,
                    value: "",
                    mutation: "Email",
                    class_styles: "col-8",
                },
                {
                    label: "Confirmar correo electrónico",
                    name: "validation",
                    type: "text",
                    no_spacing: true,
                    required: true,
                    value: "",
                    mutation: "Validation",
                    class_styles: "col-8",
                },
            ],
            terms_conditions: [
                {
                    label: "Aceptas el ",
                    label_link: "Aviso de Privacidad.",
                    link_vue: "/privacy",
                    type: "checkbox",
                    name: "terms_and_conditions",
                    value: "",
                    mutation: "TermsAndConditions",
                    required: true,
                },
            ],
        },

        api_data: {
            associate: {
                data: [],
                endpoint: "associates/associate/logged/",
                data_count: 0,
                mutation: "EditInformationData/mutationAPI_AssociateLogged",
            },
            handle_files: {
                data: {},
                endpoint: "associates/associate/save_documents/",
                mutation: "",
            },
            handle_request: {
                data: {
                    ex_associate: {},
                },
                endpoint: "associates/associate/(id)/is_modified/",
                mutation: "",
                fetch_response: "FormData/mutationAPI_ResponseHandleForm",
            },
        },
    }),
    mutations: {
        mutationAPI_AssociateLogged(state, data) {
            console.log("ASSOCIATE DATA:", data);
            state.api_data.associate.data = data;
            state.api_data.associate.data_count = data.length;

            var key = "curp";
            state.form.general_content.find(function (element) {
                if (element["name"] == key) {
                    element.value = data.ex_associate.curp;
                }
            });

            key = "first_name";
            state.form.general_content.find(function (element) {
                if (element["name"] == key) {
                    element.value = data.ex_associate.name;
                }
            });

            key = "phone";
            state.form.general_content.find(function (element) {
                if (element["name"] == key) {
                    element.value = data.phone;
                }
            });

            key = "email";
            state.form.general_content.find(function (element) {
                if (element["name"] == key) {
                    element.value = data.email;
                }
            });
        },

        mutation_FirstName(state, newValue) {
            let key = "first_name";
            state.form.general_content.find(function (element) {
                if (element["name"] == key) {
                    element.value = newValue;
                }
            });
        },

        mutation_Curp(state, newValue) {
            let key = "curp";
            state.form.general_content.find(function (element) {
                if (element["name"] == key) {
                    element.value = newValue.toUpperCase();
                }
            });
        },

        mutation_Phone(state, newValue) {
            let key = "phone";
            state.form.general_content.find(function (element) {
                if (element["name"] == key) {
                    element.value = newValue;
                }
            });
        },

        mutation_Email(state, newValue) {
            let key = "email";
            state.form.general_content.find(function (element) {
                if (element["name"] == key) {
                    element.value = newValue;
                    // console.log(newValue);
                }
            });
        },

        mutation_Validation(state, newValue) {
            let key = "validation";
            state.form.general_content.find(function (element) {
                if (element["name"] == key) {
                    element.value = newValue;
                    // console.log(newValue);
                }
            });
        },

        mutation_TermsAndConditions(state, newValue) {
            let key = "terms_and_conditions";
            state.form.terms_conditions.find(function (element) {
                if (element["name"] == key) {
                    element.value = newValue;
                }
            });
        },

        mutationAPI_ResponseHandleForm(state, data) {
            state.success_form_sended = data;
        },
    },
    actions: {
        async getAssociate({ state, dispatch }) {
            dispatch(
                "AxiosVuex/__request_gt",
                {
                    endpoint: state.api_data.associate,
                    mutation: [],
                },
                { root: true }
            ).then(() => {
                state.show_form = true;
                // this._state.data.app_loaded = true;
            });
        },
        async send_exassociate_data({ state, commit, dispatch }) {
            console.log("ENTERING SENDING");
            console.log(state.form_sended);
            // if (state.form_sended) {
            //   return;
            // }
            commit(
                "validateFields",
                { namespace: "EditInformationData", state: "terms_conditions" },
                { root: true }
            );

            if (state.form_error) {
                return false;
            }

            commit(
                "validateFields",
                {
                    namespace: "EditInformationData",
                    state: "general_content",
                    beneficiaries:
                        state.api_data.associate.data.beneficiaries.length > 0
                            ? state.api_data.associate.data.beneficiaries
                            : false,
                },
                { root: true }
            );
            if (state.form_error) {
                return false;
            }

            let pointer = state.form.general_content;
            console.log("POINTER:", pointer);
            console.log("API DATA:", state.api_data.associate.data);
            state.api_data.handle_request.data.ex_associate = {
                curp: pointer[0].value,
                fullname: pointer[1].value,
                phone: pointer[2].value,
                email: pointer[3].value,
            };

            try {
                commit(
                    "replace_parameters_url",
                    {
                        namespace: "EditInformationData",
                        api: "handle_request",
                        parameters: {
                            id: state.api_data.associate.data.id,
                        },
                    },
                    { root: true }
                );

                commit("app_loaded_set", false, { root: true });
                state.form_sended = true;
                state.show_form = false;

                // dispatch('AxiosVuex/__request_update', {
                //     endpoint: state.api_data.handle_request,
                //     mutation: []
                // }, { root: true }).then(() => {
                //     if (state.success_form_sended == 200) {
                //         dispatch('send_documents')
                //     } else {
                //         state.form_sended = false
                //         state.show_form = true
                //     }
                // })

                console.log("Sending update request");
                const response = await dispatch(
                    "AxiosVuex/__request_update",
                    {
                        endpoint: state.api_data.handle_request,
                        mutation: [],
                    },
                    { root: true }
                );

                commit("rt_redirect", "progress", { root: true });

                console.log("AFTER REQUEST:", response);
            } catch (e) {
                state.form_sended = false;
                state.show_form = true;
            }

            console.log("END");
        },
    },
};
