<template>
  <div v-show="$store.state.app_loaded">
    
    <div v-if="!st('show_form')" class="main-activity-loaded">
      <ActivityIndicatorComponent/>
    </div>

    <div v-else>
      <NavbarBoschComponent/>
      <StatusComponent/>
      
      <div class="ptu_exassociates_form" id="ptu_exassociates_form">
        <div class="row">
          
          <div class="col-lg-12">
            <div class="ptu_exassociates_form-title font-24 text-bold mb-3">{{st('title')}}</div>
            <div>{{st('text_one')}}</div>
          </div>

          <div class="col-lg-6">

            <div class="ptu_exassociates_form-step_02">

              <div class="ptu_exassociates_form-title font-18 mb-3">{{st('general_info')}}</div>
              <div class="mb-3">{{st('text_two')}}</div>

              <div class="row">
                <InputComponent v-for="input in st('form').general_content_cfdi" :key="'input_' + input.name"
                  class="mb-4"
                  :namespace="storeRoute"
                  :settings="input"
                />
                <template v-if="st('api_data').associate.data['is_foreign']">
                  <InputComponent v-for="input in st('form').foreign_form" :key="'input_' + input.name"
                    class="mb-4"
                    :namespace="storeRoute"
                    :settings="input"
                  />
                </template>
                <template v-if="st('api_data').associate.data['is_representative']">
                  <InputComponent v-for="input in st('form').familiar_data" :key="'input_' + input.name"
                    class="mb-4"
                    :namespace="storeRoute"
                    :settings="input"
                  />
                </template>
              </div>
            </div>

          </div>

          <div class="col-lg-12 mb-4">
            <div class="ptu_exassociates_form-title font-24 text-center mb-3">
              <router-link to="/login">
                <ButtonComponent v-if="!st('form_sended')"
                  :name="'submit'"
                  :label="'ENVIAR'"
                  @click.prevent="ac('send_data')"
                  :enable="true"
                />
                <ButtonComponent v-else
                  :name="'submit'"
                  :label="'ENVIAR'"
                  :enable="false"
                />
              </router-link>
            </div>
            
          </div>

        </div>

      </div>

      <FooterComponent/>
    </div>
  </div>
</template>
  
<script>
  import NavbarBoschComponent from '../components/NavbarBoschComponent.vue'
  import StatusComponent from '../components/StatusComponent.vue'
  import InputComponent from '../components/InputComponent.vue'
  import ButtonComponent from '../components/ButtonComponent.vue'
  import FooterComponent from '../components/FooterComponent.vue'
  import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent.vue'
  
  export default {
    props: {
      storeRoute: {
        require: true,
        Type: String
      }
    },
    components: {
      NavbarBoschComponent,
      StatusComponent,
      FooterComponent,
      InputComponent,
      ButtonComponent,
      ActivityIndicatorComponent
    },
    mounted() {
      let vm = this
      let loop_interval = setInterval(() => {
        if (vm.loaded) {
          vm.ac('getAssociate')
          clearInterval(loop_interval)
        }
      }, 1000);
    }
  }
</script>
<style lang="scss">
  @import "../assets/styles/views/_formView.scss";
</style>