<template>
  <div v-show="$store.state.app_loaded">
    <div v-if="!st('show_form')" class="main-activity-loaded">
      <ActivityIndicatorComponent />
    </div>
    <div v-else>
      <NavbarBoschComponent />
      <StatusComponent />

      <div class="ptu_exassociates_form" id="ptu_exassociates_form">
        <div class="row">
          <div class="col-lg-12 mb-4">
            <div class="ptu_exassociates_form-title font-24 text-bold mb-3">{{ st("title") }}</div>
            <div>{{ st("text_one") }}</div>
          </div>

          <div class="col-lg-6">
            <!-- <div class="ptu_exassociates_form-step_01">
              <InputComponent
                v-for="input in st('form').is_ex_associate"
                :key="'input_' + input.name"
                class="mb-4"
                :namespace="storeRoute"
                :settings="input"
              />
            </div> -->

            <div class="ptu_exassociates_form-step_02">
              <div class="ptu_exassociates_form-title font-18 mb-3">{{ st("general_info") }}</div>
              <div class="mb-3">{{ st("text_two") }}</div>

              <div class="row">
                <InputComponent
                  v-for="input in st('form').general_content"
                  :key="'input_' + input.name"
                  class="mb-4"
                  :namespace="storeRoute"
                  :settings="input"
                />
                <!-- <InputComponent
                  v-for="input in st('form').general_content_cfdi"
                  :key="'input_' + input.name"
                  class="mb-4"
                  :namespace="storeRoute"
                  :settings="input"
                /> -->
                <InputComponent
                  v-for="input in st('form').terms_conditions"
                  :key="'input_' + input.name"
                  class="mb-4"
                  :namespace="storeRoute"
                  :settings="input"
                />
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-6">
            <div class="ptu_exassociates_form-step_03" v-if="!gt('getIsFamiliar')">
              <div class="ptu_exassociates_form-title font-18 mb-3">{{ st("aditional_info") }}</div>
              <div class="mb-3">{{ st("text_three") }}</div>

              <div class="row">
                <InputComponent
                  v-for="input in st('form').aditional_data_1"
                  :key="'input_' + input.name"
                  class="mb-4"
                  :namespace="storeRoute"
                  :settings="input"
                />
              </div>

              <div class="row" v-if="gt('getIsForeign')">
                <InputComponent
                  v-for="input in st('form').foreign_form"
                  :key="'input_' + input.name"
                  class="mb-4"
                  :namespace="storeRoute"
                  :settings="input"
                />
              </div>

              <div class="row">
                <InputComponent
                  v-for="input in st('form').aditional_data_2"
                  :key="'input_' + input.name"
                  class="mb-4"
                  :namespace="storeRoute"
                  :settings="input"
                />
              </div>
            </div>

            <div class="ptu_exassociates_form-step_04" v-if="gt('getIsFamiliar')">
              <div class="ptu_exassociates_form-title font-18 mb-3">{{ st("familiar_info") }}</div>
              <div class="mb-3">{{ st("text_four") }}</div>

              <div class="row">
                <InputComponent
                  v-for="input in st('form').familiar_data"
                  :key="'input_' + input.name"
                  class="mb-4"
                  :namespace="storeRoute"
                  :settings="input"
                />
              </div>
            </div> -->

          <!-- <div class="ptu_exassociates_form-step_05" v-if="gt('getIsFoodPension')">
              <FoodBeneficiaryComponent
                v-for="(input, key) in st('form').beneficiaries_content"
                :key="'input_' + input.name"
                class="mb-4 row"
                :namespace="storeRoute"
                :settings="input"
                :keyNumber="key + 1"
                @add_beneficiary="handleAddBeneficiary"
                @remove_beneficiary="mt('BeneficiaryRemoveStructure', $event - 1)"
              />

              <div class="ptu_beneficiaries">
                <div class="button-container">
                  <button
                    :disabled="canAddBeneficiary"
                    @click="handleAddBeneficiary"
                    class="btn-primary"
                    style="margin-right: 25px"
                  >
                    <span class="plus-icon">+</span>
                  </button>
                  <button
                    :disabled="!canRemoveBeneficiary"
                    @click="handleRemoveBeneficiary"
                    class="btn-primary"
                  >
                    <span class="plus-icon">-</span>
                  </button>
                </div>
              </div>
            </div> -->
          <!-- </div>  -->

          <div class="col-lg-12 mb-4">
            <div class="ptu_exassociates_form-title font-24 text-center mb-3">
              <ButtonComponent
                v-if="!st('form_sended')"
                :name="'submit'"
                :label="'Actualizar'"
                @click="ac('send_exassociate_data')"
                :enable="true"
              />
              <ButtonComponent v-else :name="'submit'" :label="'Actualizar'" :enable="false" />
            </div>
          </div>
        </div>
      </div>

      <FooterComponent />
    </div>
  </div>
</template>

<script>
import NavbarBoschComponent from "../components/NavbarBoschComponent.vue";
import StatusComponent from "../components/StatusComponent.vue";
import InputComponent from "../components/InputComponent.vue";
// import FoodBeneficiaryComponent from "../components/FoodBeneficiaryComponent.vue";
import ButtonComponent from "../components/ButtonComponent.vue";
import FooterComponent from "../components/FooterComponent.vue";
import ActivityIndicatorComponent from "../components/ActivityIndicatorComponent.vue";

export default {
  props: {
    storeRoute: {
      require: true,
      Type: String,
    },
  },
  components: {
    NavbarBoschComponent,
    StatusComponent,
    FooterComponent,
    InputComponent,
    ButtonComponent,
    // FoodBeneficiaryComponent,
    ActivityIndicatorComponent,
  },
  //   computed: {
  //     canRemoveBeneficiary() {
  //       return this.$store.state.FormData.form.beneficiaries_content.length > this.$store.state.FormData.form.original_size;
  //     },
  //     canAddBeneficiary() {
  //       return this.$store.state.FormData.form.beneficiaries_content.length == 5;
  //     }
  //   },
  //   methods: {
  //     handleAddBeneficiary() {
  //       this.$store.dispatch('FormData/addBeneficiaryAndUpdateDomain');
  //     },
  //     handleRemoveBeneficiary() {
  //       this.$store.commit('FormData/mutation_BeneficiaryRemoveStructure');
  //     }
  //   },
  mounted() {
    let vm = this;
    let loop_interval = setInterval(() => {
      if (vm.loaded) {
        // vm.ac('getDomains')
        // vm.ac('getDomainsBeneficiary')
        vm.ac("getAssociate");
        // vm.ac('getLadas')
        // vm.ac('getBeneficiaries')

        clearInterval(loop_interval);
      }
    }, 1000);
  },
};
</script>
<style lang="scss">
@import "../assets/styles/views/_formView.scss";
</style>
