import { createStore } from "vuex";
import router from "../router";

const req = require.context("./", true, /\.(js)$/i);
let modules = {};

req.keys().map((key) => {
    const name = key.match(/\w+/)[0];
    if (name != "index") modules[name] = req(key).default;
});

function validateEmail(element, email, store, namespace) {
    element["class_styles"] = "class_styles" in element ? element["class_styles"] : "";

    // Check if the email value has changed
    if (element["value"] !== email[0]["value"]) {
        store[namespace]["form_error"] = true;
        element["class_styles"] += " error_validate_email";
    }

    // Validate email format
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(element["value"])) {
        store[namespace]["form_error"] = true;
        element["class_styles"] += " error_validate_format";
        console.log("Invalid email format");
    }
}

const Store = createStore({
    state: {
        application: process.env.VUE_APP_APPLICATION_NAME,
        company: process.env.VUE_APP_COMPANY_NAME,
        footer: process.env.VUE_APP_FOOTER,
        app_loaded: false,
    },
    modules: modules,
    mutations: {
        app_loaded_set(state, value) {
            this.app_loaded = value;
        },
        validateFields(store, data) {
            console.log(data);
            console.log(store);

            store[data["namespace"]]["form_error"] = false;
            let elements =
                "state" in data
                    ? store[data["namespace"]].form[data["state"]]
                    : store[data["namespace"]].form;
            let email = elements.filter((element) => element["name"] == "validation");
            console.log(elements);
            for (let element of elements) {
                if ("is_array" in data) {
                    let value = element.filter((item) => item["name"] == "validation");
                    console.log(value);

                    for (let item of element) {
                        if (item["name"] == "email") {
                            validateEmail(item, value, store, data["namespace"]);
                        }

                        item["class_styles"] = "class_styles" in item ? item["class_styles"] : "";
                        if (item["value"] == "") {
                            item["class_styles"] = item["class_styles"] + " error_validate";
                            store[data["namespace"]]["form_error"] = true;
                        } else {
                            item["class_styles"] = item["class_styles"].replace(
                                "error_validate",
                                ""
                            );
                        }
                    }
                } else if ("required" in element && element["required"]) {
                    element["class_styles"] =
                        "class_styles" in element ? element["class_styles"] : "";
                    if (
                        element["value"] == "" ||
                        (element["required_length"] != "" &&
                            element["required_length"] != undefined &&
                            element["value"].toString().length != element["required_length"])
                    ) {
                        element["class_styles"] = element["class_styles"] + " error_validate";
                        store[data["namespace"]]["form_error"] = true;
                    } else {
                        element["class_styles"] = element["class_styles"].replace(
                            "error_validate",
                            ""
                        );
                    }
                } else if ("required_value" in data) {
                    element["class_styles"] =
                        "class_styles" in element ? element["class_styles"] : "";
                    if (element["value"] != data["required_value"]) {
                        element["class_styles"] = element["class_styles"] + " error_validate";
                        store[data["namespace"]]["form_error"] = true;
                    } else {
                        element["class_styles"] = element["class_styles"].replace(
                            "error_validate",
                            ""
                        );
                    }
                }

                if (element["name"] === "email") {
                    validateEmail(element, email, store, data["namespace"]);

                    if (data.beneficiaries && data.namespace == "FormData") {
                        let beneficiaries = data["beneficiaries"];
                        console.log(typeof beneficiaries);

                        for (let beneficiary of beneficiaries) {
                            console.log(beneficiary);
                            // Get beneficiary email
                            let email = beneficiary.filter((item) => item["name"] == "email");

                            // Validate that beneficiary email is not the same as the main email
                            if (element["value"] == email[0]["value"]) {
                                console.log("Beneficiary email is the same as the main email");
                                store.ModalData.content =
                                    "Uno de tus beneficiarios tiene el mismo correo que tú.\nPor favor, verifica y modifica los correos electrónicos de tus beneficiarios.".replace(
                                        /\n/g,
                                        "<br>"
                                    );

                                store.ModalData.show_modal = true;

                                store[data["namespace"]]["form_error"] = true;
                            }
                        }
                    } else if (data.beneficiaries && data.namespace == "EditInformationData") {
                        let beneficiaries = data["beneficiaries"];

                        for (let beneficiary of beneficiaries) {
                            console.log(beneficiary["email"]);
                            console.log(element["value"]);

                            // Validate that beneficiary email is not the same as the main email
                            if (element["value"] == beneficiary["email"]) {
                                console.log("Beneficiary email is the same as the main email");
                                store.ModalData.content =
                                    "Uno de tus beneficiarios que registraste anteriormente, cuenta con el mismo correo.\nPor favor ingresa un correo distinto e intenta de nuevo".replace(
                                        /\n/g,
                                        "<br>"
                                    );

                                store.ModalData.show_modal = true;

                                store[data["namespace"]]["form_error"] = true;
                            }
                        }
                    }
                }
            }
        },
        replace_parameters_url(store, data) {
            let has_parameters =
                store[data["namespace"]].api_data[data["api"]].endpoint.match(/\(([^)]+)\)/g);
            if (has_parameters) {
                let url_parameters = has_parameters.map((item) =>
                    item.replace("(", "").replace(")", "")
                );
                url_parameters.forEach((parameter) => {
                    store[data["namespace"]].api_data[data["api"]].endpoint = store[
                        data["namespace"]
                    ].api_data[data["api"]].endpoint.replace(
                        "(" + parameter + ")",
                        data["parameters"][parameter]
                    );
                });
            }
        },
        rt_redirect(store, route_name) {
            if (window.location.pathname == "/disable" && route_name == "disable") {
                return 0;
            }
            store.app_loaded = false;
            if (route_name === "progress") {
                window.location.href = "/ptu-exasociados-on-process";
            } else {
                router.push({ name: route_name, replace: true });
            }
        },
    },
});

export default Store;
