import { createRouter, createWebHistory } from "vue-router";
import { useCookies } from "vue3-cookies";

import LoginView from "../views/LoginView";
import FormView from "../views/FormView";
import PendingDocumentsView from "../views/PendingDocumentsView";
import PrivacyView from "../views/PrivacyView";
import DisableView from "../views/DisableView";
import ProgressView from "../views/ProgressView";
import TemporalView from "../views/TemporalView";
import store from "../store";
import EditDataView from "@/views/EditDataView.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: LoginView,
      props: {
        storeRoute: "LoginData",
      },
      meta: { requiresAuth: false },
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,
      props: {
        storeRoute: "LoginData",
      },
      meta: { requiresAuth: false },
    },
    {
      path: "/temporal_maintenance",
      name: "maintenance",
      component: TemporalView,
      props: {
        storeRoute: "TemporalData",
      },
      meta: { enable: true },
    },
    {
      path: "/ptu-exasociados",
      name: "form-exasociados",
      component: FormView,
      props: {
        storeRoute: "FormData",
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/ptu-exasociados-documentacion-pendiente",
      name: "documentacion-pendiente",
      component: PendingDocumentsView,
      props: {
        storeRoute: "PendingDocumentsData",
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/ptu-exasociados-on-process",
      name: "progress",
      component: ProgressView,
      props: {
        storeRoute: "ProgressData",
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: PrivacyView,
      props: {
        storeRoute: "PrivacyData",
      },
      meta: { requiresAuth: false },
    },
    {
      path: "/disable",
      name: "disable",
      component: DisableView,
      props: {
        storeRoute: "DisableData",
      },
      meta: { requiresAuth: false },
    },
    {
      path: "/ptu-exasociados-edit-information",
      name: "edit-information",
      component: EditDataView,
      props: {
        storeRoute: "EditInformationData",
      },
      meta: { requiresAuth: false },
    },
  ],
});

/* eslint-disable */
router.beforeEach(async (to, from) => {
  const { cookies } = useCookies();
  if (!store.state.AxiosVuex.initialized) {
    await store.dispatch("AxiosVuex/initializeApp");
  }
  // console.log("Current Cookies:", {
  //     token: cookies.get('_token'),
  //     periodEnabled: cookies.get('_period_enabled'),
  // });
  console.log("V", store.state.AxiosVuex.initialized);
  if (to.name != "maintenance" && process.env.VUE_APP_MAINTENANCE_MODE == "1") {
    return {
      name: "maintenance",
    };
  }
  if (to.name == "maintenance" && process.env.VUE_APP_MAINTENANCE_MODE == "0") {
    return {
      name: "login",
    };
  }

  /** RULES ROUTE ANNONYMOUS */
  if (!cookies.isKey("_token") && !cookies.get("_token")) {
    //If period_enabled == false
    if (cookies.get("_period_enabled") != "true" && to.name != "disable") {
      return {
        name: "disable",
      };
    }

    //If period_enabled == true
    if (cookies.get("_period_enabled") == "true" && to.name != "login") {
      return {
        name: "login",
      };
    }
  }

  /** RULES ROUTE LOGGED */
  if (cookies.isKey("_token") && cookies.get("_token")) {
    if (to.name == "disable" && cookies.get("_period_enabled") == "true") {
      return {
        name: "form-exasociados",
      };
    }
    if (to.name == "login") {
      return {
        name: "form-exasociados",
      };
    }
    if (to.name == "home") {
      return {
        name: "form-exasociados",
      };
    }
  }

  /** RULES ROUTE ANNONYMOUS */
  /* if (!cookies.isKey('_token')) {
        if (to.meta.requiresAuth) {
            return {
                name: 'login',
            }
        }

    }*/
});

export default router;
